import * as React from 'react';

import Container from '@components/Container';
import Wrapper from '@components/layout/Wrapper';
import Layout from '@mainComponents/Layout';
import Seo from '@mainComponents/Seo';

const NotFoundPage = () => (
	<Layout>
		<Wrapper>
			<Container>
				<h1>404: Not Found</h1>
				<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
			</Container>
		</Wrapper>
	</Layout>
);

export const Head = () => <Seo title="404: Not Found" />;

export default NotFoundPage;
