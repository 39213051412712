import styled from '@emotion/styled';
import settings from '@settings';

const Wrapper = styled.div(settings.mq({
	marginLeft: 'auto',
	paddingTop: settings.sizes.small,
	paddingBottom: settings.sizes.small,
	marginRight: 'auto',
	fontSize: ['13px', '13px', '14px', '15px', '15px']
}),
props => ({backgroundColor: props.background})

);

export default Wrapper;