/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Seo = ({ description, title, children, imageUrl }) => {

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
    	`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<>
			<title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
			<script type="text/javascript" src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
			<meta name="description" content={metaDescription} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={metaDescription} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={imageUrl} />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content={site.siteMetadata?.author || ''} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={metaDescription} />
			<script type="application/ld+json">
				{`
				{
				"@context": "https://schema.org",
				"@type": "WebPage",
				"name": "Womens Nordic Combined",
				"alternateName": "WNC",
				"url": "https://www.nocogirls.com/",
				"logo": "https://www.nocogirls.com/static/80fbe6837ea739c1cf8b17841ba84ede/561e3/logo_schwarz.webp",
				"description": "${site.siteMetadata.description}",
				"contactPoint": {
					"@type": "ContactPoint",
					"email": "nocogirls@nocogirls.com",
					"contactType": "customer service",
					"contactOption": "HearingImpairedSupported",
					"areaServed": "DE",
					"availableLanguage": "German"
				},
				"sameAs": [
					"https://www.instagram.com/nocogirls/"
				]
				}
			`}
			</script>
			<script type='application/ld+json'>
				{`
				{
					"@context": "https://schema.org/", 
					"@type": "BreadcrumbList", 
					"itemListElement": [{
						"@type": "ListItem", 
						"position": 1, 
						"name": "WOMENS NORDIC COMBINED",
						"item": "https://www.nocogirls.com/"  
					},{
						"@type": "ListItem", 
						"position": 2, 
						"name": "Gallery",
						"item": "https://www.nocogirls.com/gallery"  
					},{
						"@type": "ListItem", 
						"position": 3, 
						"name": "Athletes",
						"item": "https://www.nocogirls.com/athletes"  
					},{
						"@type": "ListItem", 
						"position": 4, 
						"name": "Calendar",
						"item": "https://www.nocogirls.com/calendar"  
					},{
						"@type": "ListItem", 
						"position": 5, 
						"name": "About us",
						"item": "https://www.nocogirls.com/about"  
					}]
				}
			`}
			
			</script>
			{children}
		</>
	);
};

Seo.defaultProps = {
	description: '',
	children: '',
	imageUrl: ''
};

Seo.propTypes = {
	children: PropTypes.node,
	description: PropTypes.string,
	title: PropTypes.string.isRequired,
	imageUrl: PropTypes.string
};

export default Seo;
